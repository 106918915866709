body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-content {
  /*background-color: #3b4252;  background-color: #282c34;   #2e3440   #2d323d  111318  c47474  661f1f 722727 */
  background-color: #3b4252; /*353b49;*/
  padding: 4em 3em;
  color: #ecefe4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

#particlePathWrapper {
  top: 50%;
  /* left: 25%;
  */
  position: absolute;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 100%;
}

#particlePathWrapper path {
  fill: none;
  stroke: #dabdd8;
  stroke-width: 5;
  stroke-dasharray: 10, 10;
  margin: 0;
  /*transform: scale(2);*/
}

.neon {
  -webkit-animation-name: App-neon-fade;
          animation-name: App-neon-fade;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0.01;

  /*-webkit-animation: App-neon-fade 3s ease-in 7s forwards;
  animation: App-neon-fade 3s ease-in  7s forwards;*/
  /*animation: App-neon-fade 0.3s ease-in;*/
  /*-moz-animation: App-neon-fade 5s 15s;
  -webkit-animation: App-neon-fade 5s 15s;
  animation: App-neon-fade 5s 15s;*/
}

.Quark {
  position: absolute;
  opacity: 0.5;
  /*animation: App-quark-spin 1s linear 0s forwards infinite;*/

  -webkit-animation-name: App-quark-spin;

          animation-name: App-quark-spin;
  -webkit-animation-duration: 120s;
          animation-duration: 120s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  /*animation-delay: 0s;*/
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes App-quark-spin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-quark-spin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes App-neon-fade {
  from {
    opacity: 0.01;
  }
  to {
    opacity: 1;
  }
}

@keyframes App-neon-fade {
  from {
    opacity: 0.01;
  }
  to {
    opacity: 1;
  }
}

.tardis {
  -webkit-animation: App-tardis 3s ease-in;
          animation: App-tardis 3s ease-in;
}

@-webkit-keyframes App-tardis {
  from {
    -webkit-transform: translate(20px, 0);
            transform: translate(20px, 0);
  }
  to {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }
}

@keyframes App-tardis {
  from {
    -webkit-transform: translate(20px, 0);
            transform: translate(20px, 0);
  }
  to {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*Not used*/
@-webkit-keyframes App-particle-spin {
  0% {
    -webkit-transform: translate(-600px, -100px);
            transform: translate(-600px, -100px);
  }

  10% {
    -webkit-transform: translate(-100px, -50px);
            transform: translate(-100px, -50px);
  }
  12.5% {
    -webkit-transform: translate(-50px, 0px);
            transform: translate(-50px, 0px);
  }
  15% {
    -webkit-transform: translate(0px, 100px);
            transform: translate(0px, 100px);
  }
  20% {
    -webkit-transform: translate(-100px, 200px);
            transform: translate(-100px, 200px);
  }
  100% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0);
  }
}
@keyframes App-particle-spin {
  0% {
    -webkit-transform: translate(-600px, -100px);
            transform: translate(-600px, -100px);
  }

  10% {
    -webkit-transform: translate(-100px, -50px);
            transform: translate(-100px, -50px);
  }
  12.5% {
    -webkit-transform: translate(-50px, 0px);
            transform: translate(-50px, 0px);
  }
  15% {
    -webkit-transform: translate(0px, 100px);
            transform: translate(0px, 100px);
  }
  20% {
    -webkit-transform: translate(-100px, 200px);
            transform: translate(-100px, 200px);
  }
  100% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0);
  }
}

.App-particle {
  -webkit-animation: App-particle-spin 2s linear;
          animation: App-particle-spin 2s linear;
}

.Nav-bar {
  z-index: 10;
}

.Nav-button {
  color: #ffffff;
  margin: 0 10px;
  text-decoration: none;
  z-index: 100;
}

.Nav-button:hover {
  color: #dddddd;
}

.CommitteeList {
  padding: 10px 0px 10px 150px;
  text-align: left;
}

.CommitteeListTitle {
  display: inline-block;
  width: 320px;
  text-align: left;
}

#OurCommittee {
  margin-top: -2em;
}

.titleTextFont {
  font-family: "Merriweather"; /*"Racing Sans One";*/
  /*font-size: 54px;*/
  /*font-size: 3.2rem;*/
  font-size: 2.8rem;
  color: #f24f3a;
}

@media screen and (max-width: 600px) {
  .App-content {
    background-color: #3b4252;
    padding: 40px 30px;
    color: #ecefe4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .NotDisplayOnSmallScreens {
    display: none !important;
  }

  .DisplayBlockOnSmallScreens {
    display: block !important;
    float: none !important;
    width: 100% !important;
  }

  .AboutUsSmallScreen {
    width: 100% !important;
    font-size: 14pt !important;
    padding: 20px 20px !important;
  }

  .SponsorUsSmallScreen {
    width: 100% !important;
    font-size: 14pt !important;
    padding: 20px 0px !important;
  }

  .UpcomingEventSmallScreen {
    width: 100% !important;
    font-size: 12pt !important;
    padding: 0px 0px !important;
  }

  .UpcomingEventItemWidthSmallScreen {
    width: 100% !important;
  }

  .CommitteePaddingHeadingSmallScreen {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
  }

  .CommitteePaddingPersonSmallScreen {
    margin-top: 8px !important;
    padding-top: 0px !important;
    padding-bottom: 15px !important;
  }

  .CommitteeAlignCentreSmallScreen {
    text-align: center !important;
  }

  .CommitteeList {
    padding: "10px 0px 10px 0px";
    text-align: "left";
    padding: 0;
  }

  .CommitteeListTitle {
    text-align: center;
  }
}

